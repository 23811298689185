import Head from "next/head";
import Link from "next/link";

import { Col, Row as _Row } from "react-bootstrap";

import { getProducts } from "../api/db/products";
import { transformProduct } from "../libs/tranforms";

import Picture, { ImageTransformation } from "../components/Picture";
import ProductCard from "../components/ProductCard";
import SEO from "../components/SEO";
import Layout from "../components/layout/Layout";

import styles from "../styles/index.module.scss";

const myLoader = ({ src, width, quality }) => {
  return `https://static.mellowly.eu/uploads/hp/w=${width}/${src}?w=${width}&q=${quality || 75}`;
};

const Row = ({ children, className }) => (
  <_Row className={`${styles.row}${className ? " " + className : ""}`}>{children}</_Row>
);

const Home = ({ products }) => {
  return (
    <>
      <Head>{SEO("Sklep z naturalnymi świecami sojowymi")}</Head>
      <Row className="mb-5">
        <Col md={12}>
          <Picture
            src={ImageTransformation("/uploads/hp/hp1.jpg")}
            alt=""
            cover={true}
           loading="eager"
          />
        </Col>
      </Row>
      <Row className="mb-5">
        <Col>
          <h2 className="text-center">Bestsellery</h2>
          <Row className={styles.bestsellers}>
            {products.map((x) => (
              <ProductCard product={x} key={x.slug} />
            ))}
          </Row>
        </Col>
      </Row>
      <Row>
        <div className={styles.image}>
          <Picture src={ImageTransformation("/uploads/hp/hp2.jpg")} alt="" cover={true} />
        </div>
        <div className={styles.text}>
          <h3>Natural light</h3>
          <p>
            Mellowly to marka tworzona przez parę, która chciała połączyć swoje wspólne pasje,
            tworząc produkt w 100% naturalne ręcznie robione świece sojowe. Lubimy smakować życie,
            podróżować odkrywając nowe magiczne miejsca i kolejne obłędne zapachy!
          </p>
          <p>
            Produkty Mellowly to przede wszystkim produkty, których sami długo szukaliśmy – czyli
            naturalne, ekologiczne, aromatyczne, dopracowane, tworzone z pasją i zamiłowaniem do
            produktów najwyższej jakości.
          </p>
        </div>
      </Row>
      <Row>
        <div className={styles.text}>
          <h3>HANDMADE ITEMS</h3>
          <p>
            Zapraszamy do świata zapachów, w którym potrzeba życia z naturą oraz z samym sobą jest
            najważniejsza, a magia zapachów i prostoty pozwoli na zatrzymanie się na chwile w tym
            szalonym świecie.
          </p>
          <p>
            Nasze świece są produkowane w Polsce i zawierają 100% wosku sojowego bez GMO oraz z
            wykorzystaniem wyjątkowych kompozycji zapachowych. Zapachy tworzone przez Mellowly to
            zapachy, które pozwalają stworzyć swoje własne domowe spa, zapewniając komfort oraz
            chwile odprężenia – nie ważne czy w kapciach na sofie, czy przy romantycznej kolacji.
          </p>
          <p>
            W Mellowly znacznie ważniejsza od ilości jest jakość. Każda świeca jest wykonywana
            ręcznie i z precyzyjną dokładnością. Zrelaksuj się, a my zajmiemy się reszta!
          </p>
          {/*<Button>Czytaj więcej</Button>*/}
        </div>
        <div className={styles.image}>
          <Picture src={ImageTransformation("/uploads/hp/hp3.jpg")} alt="" cover={true} />
          {/*<Image src="/hp/hp3.jpg" layout={"responsive"} width={2670} height={1780} />*/}
        </div>
      </Row>
    </>
  );
};

Home.getLayout = (page) => {
  return <Layout fluid>{page}</Layout>;
};

export async function getStaticProps(context) {
  const products = await getProducts();

  return {
    props: {
      products: products
        .filter((x) => x.stock > 0)
        .slice(0, 5)
        .map(transformProduct)
    },
    revalidate: 3600
  };
}

export default Home;
