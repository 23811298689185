import Picture, { ImageTransformation } from "./Picture";
import Price from "./widgets/Price";

import Link from "next/link";

import { Badge } from "react-bootstrap";

import styles from "./ProductCard.module.scss";

const ProductCard = ({ product }) => {
  return (
    <div className={`${styles.product} ${styles[`stock-${product.stock.toLowerCase()}`]}`}>
      <Link href={`/produkt/${product.slug}`}>
        <a>
          <Picture
            src={ImageTransformation(product.thumbnail, { c: "square" })}
            className={styles.picture}
            width={200}
            height={200}
          />
        </a>
      </Link>
      {product.stock === "LACK" && (
        <Badge bg="danger" className={styles.stockBadge}>
          Tymczasowo niedostępny
        </Badge>
      )}
      {product.stock === "FEW" && (
        <Badge bg="warning" className={styles.stockBadge}>
          Ostatnie sztuki
        </Badge>
      )}
      <div className={styles.productName}>{product.name}</div>
      <p>
        <Price price={product.price} />
      </p>
    </div>
  );
};

export default ProductCard;
