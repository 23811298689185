const Price = ({ price }) => {
  const isSale = price.regular !== price.price;
  return (
    <>
      {isSale && (
        <>
          <del>{price.regular} zł</del>{" "}
        </>
      )}
      <strong>{price.price} zł</strong>
    </>
  );
};

export default Price;
