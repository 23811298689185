/* eslint-disable  @next/next/no-img-element */
import React from "react";

import styles from "./Picture.module.scss";

const parseUrl = (url) => {
  let _url = url.split("/");

  return {
    baseUrl: _url.slice(0, _url.length - 1).join("/"),
    filename: _url[_url.length - 1],
  };
};

export const ImageTransformation = (url, options = {}) => {
  const src = parseUrl(url);
  if (src.baseUrl.substr(0, 4) !== "http") {
    src.baseUrl = `${process.env.NEXT_PUBLIC_STATIC_HOST}${src.baseUrl}`;
  }
  return `${src.baseUrl}${Object.entries(options)
    .map(([k, v]) => `/${k}=${v}`)
    .join("")}/${src.filename}`;
};

export const Gallery = ({ children }) => {
  const count = React.Children.toArray(children).filter((child) =>
    React.isValidElement(child)
  ).length;
  return <div className={`${styles.gallery} ${styles[`gallery-${count}`]}`}>{children}</div>;
};

export const SingleGallery = (props) => {
  return (
    <div className={`${styles.gallery} ${styles[`gallery-1`]}`}>
      <Picture {...props} />
    </div>
  );
};

export const Picture = ({
  src,
  alt,
  title,
  className = "",
  width,
  height,
  sizes = [200, 576, 768, 864, 1000, 1400, 1800],
  cover = false,
  loading = "lazy",
}) => {
  return (
    <picture className={`${styles.image} ${className}`}>
      <source
        type="image/webp"
        title={title}
        className={`${styles.image} ${className}`}
        srcSet={sizes
          .map(
            (s, i) =>
              `${ImageTransformation(src, {
                w: sizes[i + (cover ? 2 : 0)] || sizes[sizes.length - 1],
                f: "webp",
              })} ${s}w`
          )
          .join(", ")}
        sizes="(min-width: 1400px) 100vw, 170px"
      />
      <img
        loading={loading}
        src={src}
        alt={alt}
        width={width}
        height={height}
        title={title}
        srcSet={sizes
          .map(
            (s, i) =>
              `${ImageTransformation(src, {
                w: sizes[i + (cover ? 2 : 0)] || sizes[sizes.length - 1],
              })} ${s}w`
          )
          .join(", ")}
        sizes="(min-width: 1400px) 100vw, 170px"
      />
      {title && <span className={styles.title}>{title}</span>}
    </picture>
  );
};

export default Picture;
