const SEO = (title, description, image) => {
  const separator = " :: ";
  const fullTitle = `${
    Array.isArray(title) ? title.join(separator) : title
  }${separator}Mellowly`;
  return (
    <>
      <title>{fullTitle}</title>
      <meta name="og:title" content={fullTitle} />
      <meta name="og:description" content={description} />
      <meta name="og:image" content={image} />
    </>
  );
};

export default SEO;
